import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ReservasService } from '../component/services/reservas.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import { LoginComponent } from '../component/login/login.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';

import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';

import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ReservaClientComponent } from '../component/reserva-client/reserva-client.component';

import {HttpClient, HttpHeaders} from "@angular/common/http"





@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']


})
export class LandingComponent implements OnInit {
  today: NgbDateStruct;
  hoy = new Date();
  hoursArray: any[] = []; // Declara la propiedad hoursArray en tu componente
  itemId: string;
  date: { year: number, month: number };
  public reservasList: any[];
  public reservasList2: any[];
  public reserva: any
  public fechaLista: any[];
  public users: Array<any>;
  groupedHours: { fecha: string, hours: any[] }[] = [];

  public loadBookings: boolean;
  public isCollapsed = false;
  private ngUnsubscribe: Subject<void> = new Subject();
  fechaSeleccionada: Date;
  selected: Date = new Date();
  fechas:  ['2023-05-24', '2023-05-25'];
  fechasMarcadas = ['2023-05-24', '2023-05-25'];
  fechasReservadas: string[] = [];
  fechasOcupadas: string[] = [];

  fecha: string;
  fechaReservadas: string[] = [];

  public loadReservas: boolean;



  fechasResaltadas: string[] = ['2023-5-30', '2023-5-21', '2023-5-22', '2023-6-22']; // Arreglo con las fechas a resaltar
  selectedDate: NgbDate;
  disabled = true;

  collapsed = true;
  es: any;
  constructor(
    private calendar: NgbCalendar, private config: NgbDatepickerConfig,
    private modalService: NgbModal,
    private reservasService: ReservasService,
    private translateService: TranslateService,


  ) {



  }





  ngOnInit() {

    const now = new Date();  // Obtén la fecha actual
    this.today = new NgbDate(now.getFullYear(), now.getMonth() + 1, now.getDate());  // Asigna la fecha actual a 'today'
    this.date = { year: this.today.year, month: this.today.month };
    this.today = this.calendar.getToday();
    this.getReservas();
  }


  isWeekend = (date: NgbDate) => this.calendar.getWeekday(date) >= 6;
  isSemana = (date: NgbDate) => this.calendar.getWeekday(date) <= 5;


  isDisponible(date: NgbDate): boolean {
    const dateString = `${date.year}-${date.month}-${date.day}`;
    return this.fechasReservadas.includes(dateString) ;

  }

 noDisponible(date: NgbDate): boolean {
    const dateString = `${date.year}-${date.month}-${date.day}`;
    return this.fechasOcupadas.includes(dateString) ;
  }

  // onDateSelect2(date: NgbDate) {
  //   const dateString = `${date.year}-${date.month}-${date.day}`;

  //   const reserva = this.reservasList.find(item => {


  //     const fecha = new Date(item.date);
  //     const year = fecha.getFullYear();
  //     const month = fecha.getMonth() + 1;
  //     const day = fecha.getDate();
  //     const fechaFormateada = `${year}-${month}-${day}`;

  //     if (fechaFormateada === dateString) {



  //       this.hoursArray = item.hours; // Asigna el array hours a la propiedad hoursArray del componente
  //       this.itemId = item.id;
  //       this.fecha = fechaFormateada;
  //       this.loadReservas = false
  //       return true;
  //     } else {
  //       this.loadReservas = true
  //     }

  //     return false;
  //   });

  // }

  onDateSelect(date: NgbDate) {
    const dateString = `${date.year}-${date.month}-${date.day}`;

    const reservas = this.reservasList.filter(item => {
      const fecha = new Date(item.date);
      const year = fecha.getFullYear();
      const month = fecha.getMonth() + 1;
      const day = fecha.getDate();
      const fechaFormateada = `${year}-${month}-${day}`;

      return fechaFormateada === dateString;
    });

    if (reservas.length > 0) {
      const hoursArray: any[] = [];

      // for (const reserva of reservas) {
      //   hoursArray.push(...reserva.hours);
      // }

      this.hoursArray = reservas;
      this.itemId = reservas[0].id;

      this.fecha = dateString;
      this.loadReservas = false;
    } else {
      this.loadReservas = true;
    }
  }


  meterDatos(i: any, index: any, city: any, id: any) {

    const modalRef = this.modalService.open(ReservaClientComponent);
    modalRef.componentInstance.hoursArray = this.hoursArray[i].hours;
    modalRef.componentInstance.itemId = id;
    modalRef.componentInstance.fecha = this.fecha;
    modalRef.componentInstance.selectedIndex = index;
    modalRef.componentInstance.email =  this.hoursArray[i].email;
    modalRef.componentInstance.city = city;





  }

  openLogin() {
    this.modalService.open(LoginComponent);
  }







  alert(){
    Swal.fire('Si deseas reservar la fecha, deberás registrarte')
   }

   getReservas(){
    this.reservasService.reservaList2()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(res => {

      this.reservasList = [];
      this.fechasReservadas = [];
      this.fechasOcupadas = [];
      if (res) {
        res.forEach((item: any) => {



        const data = item.payload.doc.data()

        const fecha = new Date(data.date);


        const year = fecha.getFullYear(); // Año
        const month = fecha.getMonth() + 1; // Mes (los meses comienzan desde 0)
        const day = fecha.getDate(); // Día
        const fechaFormateada = `${year}-${month}-${day}`;

        const fechaActual = new Date();

        if (fecha > fechaActual) {

          const userClientIds = data.hours.map((hour: any) => hour.user_client_id);

          if (userClientIds.every((id: any) => id)) {

            this.fechasOcupadas.push(fechaFormateada);




          }


          this.reservasList.push(data);

          this.fechasReservadas.push(fechaFormateada);



        }


        })



      }


    });
  }

   getReservas2() {
    this.reservasService.reservaList().then(res => {



      this.reservasList = [];
      this.fechasReservadas = [];
      if (!res.empty) {
        res.docs.forEach((item: any) => {
          const data = item.data();
          const fecha = new Date(data.date);

          const year = fecha.getFullYear(); // Año
          const month = fecha.getMonth() + 1; // Mes (los meses comienzan desde 0)
          const day = fecha.getDate(); // Día

          const fechaFormateada = `${year}-${month}-${day}`;

          const fechaActual = new Date();

          if (fecha > fechaActual) {
            data.id = item.id;
            this.reservasList.push(data);
            this.fechasReservadas.push(fechaFormateada);
          }
        });

      }

      this.loadBookings = true;
    }).catch(error => {
      console.log('No se ha podido recuperar los bookings: ' + error);
      this.loadBookings = true;
      console.log(error);
    });
  }


  getDate(date: string): string {
    return new Date(date).toLocaleDateString('en-US');
  }




}
