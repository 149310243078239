import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentSnapshot } from '@angular/fire/compat/firestore';
import { ReservaModel } from '../models/reserva-model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {finalize, map} from 'rxjs/operators';

import { first } from 'rxjs/operators';

import { switchMap } from 'rxjs/operators';

interface Reserva {
  hours: any[]; // Reemplaza 'any' con el tipo adecuado para el array 'hours'
  // Otras propiedades de la reserva si las hay
}

interface Reserva {
  id: string;
  codigoReserva: string
  // Resto de propiedades de la reserva
}

@Injectable({
  providedIn: 'root'
})
export class ReservasService {
  hoursArrayIndex: any[] = []; // Inicializa hoursArray como un array vacío

  constructor(
    public db: AngularFirestore,
    private http: HttpClient,


  ) {
  }

  reservaList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.db.collection<ReservaModel>('reservas').get().subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }


  reservaListEmail(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.db.collection<ReservaModel>('reservas', ref => ref.where('email', '==', email)).get().subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }
  



  reservaList2() {
    return this.db.collection('reservas').snapshotChanges();
  }



  getReservaById(reservaId: string): Observable<ReservaModel> {
    return this.db.collection<ReservaModel>('reservas').doc(reservaId).valueChanges();
  }

  getuserby(reservaId: string): Observable<any> {
    return this.db.collection('reservas').doc(reservaId).get().pipe(
      map((snapshot: DocumentSnapshot<any>) => {
        if (snapshot.exists) {
          const data = snapshot.data();
          return { id: snapshot.id, ...data } as any;
        }
        return null; // Manejar el caso en el que el documento no existe
      })
    );
  }


  // reservaList(): Observable<any> {
  //   return this.db.collection<ReservaModel>('reservas').snapshotChanges();
  // }



  deleteReserva(id) {
    return this.db.doc(id).delete();
  }

  getDocument(id: string) {
    return this.db.collection('reservas').doc(id).get();
  }


  getDocument2(id: any) {
    return this.db.collection('reservas').doc(id).snapshotChanges();
  }



  updateTareasForm(tareasFormKey, value) {
    return this.db.collection('reservas.hour').doc(tareasFormKey).update(value);
  }



  // reservaAdd(model): Promise<any> {
  //   return this.db.collection('reservas').add(model);
  // }


  reservaAdd(model): Promise<any>  {
    return this.db.collection('reservas').add(model)
      .then(ref => {
        ref.set({id: ref.id}, {merge: true});
      });
  }

  reservaUpdate(model): Promise<any> {
    return this.db.collection('reservas').doc(model.id).set(model);
  }

  updateReservas(key: string, data: any) {
    return this.db.collection('reservas').doc(key).update(data);
  }

  reservaDelete(model): Promise<any> {
    return this.db.collection('reservas').doc(model.id).delete();
  }

  updateDocument(key: string, data: any) {
    return this.db.collection('reservas').doc(key).update(data);
  }



  getEvents() {
    return this.http.get<any>('assets/scheduleevents.json')
      .toPromise()
      .then(res => <any[]>res.data)
      .then(data => { return data; });
    }



    getDataForms() {
      return this.db.collection('reservas').snapshotChanges();
    }

    getTareasForms() {
      return this.db.collection('reservas').snapshotChanges();
    }


    getUsers() {
      return this.db.collection('reservas').snapshotChanges();
    }


    updateUser(userKey: any, value: any) {
      return this.db.collection('users').doc(userKey).update(value);
    }


    cancelar(index: number, id: string) {
      const reservaRef = this.db.collection('reservas').doc(id);
    }



    updateHourIndex(key: string, data: any) {
      return this.db.collection('reservas').doc(key).update(data);

    }
    

    async updateCoursesInstructor(course: any, index: any, value: any): Promise<void> {
      const docRef = this.db.collection('reservas').doc(course);
  
      const updateData = {
          [`hours.${index}`]: {
              nombre: value.nombre,
              apellidos: value.apellidos,
              email: value.email,
              telefono: value.telefono,
              dni: value.dni,
              tipo: value.tipo,
              licencia: value.licencia,
              examen: value.examen,
              tramitacion: value.tramitacion,
              user_client_id: value.dni,
          }
      };
  
      try {
          const doc = await docRef.get().pipe(first()).toPromise();
  
          if (doc.exists) {
              const hoursData = (doc.data() as { hours: any }).hours;
              const existingData = hoursData[index]; // Obtener los datos existentes en el índice
  
              if (existingData) {
                  // Fusionar los datos existentes con los nuevos datos
                  hoursData[index] = { ...existingData, ...updateData[`hours.${index}`] };
              } else {
                  hoursData[index] = updateData[`hours.${index}`];
              }
  
              await docRef.set({ hours: hoursData }, { merge: true });
              console.log('Mapa actualizado correctamente');
              return; // IMPORTANTE: Asegura que la función devuelva una Promise<void>
          } else {
              throw new Error('El documento no existe');
          }
      } catch (error) {
          console.error('Error al actualizar el mapa', error);
          throw error; // IMPORTANTE: Relanza el error para que el `catch` de la función `update()` lo capture
      }
  }
  




    async bloquearFecha(course: any, index: any, value: any) {
      const docRef = this.db.collection('reservas').doc(course);

      const updateData = {
        [`hours.${index}`]: {

          user_client_id: value.user_client_id,
        }
      };

      try {
        const doc = await docRef.get().pipe(first()).toPromise();

        if (doc.exists) {
          const hoursData = (doc.data() as { hours: any }).hours;
          const existingData = hoursData[index]; // Obtener los datos existentes en el índice

          if (existingData) {
            // Fusionar los datos existentes con los nuevos datos
            hoursData[index] = { ...existingData, ...updateData[`hours.${index}`] };
          } else {
            hoursData[index] = updateData[`hours.${index}`];
          }

          await docRef.set({ hours: hoursData }, { merge: true });
          console.log('Mapa actualizado correctamente');
        } else {
          throw new Error('El documento no existe');
        }
      } catch (error) {
        console.error('Error al actualizar el mapa', error);
      }
    }

    async guardarDatosBloqueados(course: any, index: any, value: any) {
      const docRef = this.db.collection('reservas').doc(course);

      const updateData = {
        [`hours.${index}`]: {
          nombre: value.nombre,
          email: value.email,
          telefono: value.telefono,
          user_client_id: value.user_client_id,
        }
      };

      try {
        const doc = await docRef.get().pipe(first()).toPromise();

        if (doc.exists) {
          const hoursData = (doc.data() as { hours: any }).hours;
          const existingData = hoursData[index]; // Obtener los datos existentes en el índice

          if (existingData) {
            // Fusionar los datos existentes con los nuevos datos
            hoursData[index] = { ...existingData, ...updateData[`hours.${index}`] };
          } else {
            hoursData[index] = updateData[`hours.${index}`];
          }

          await docRef.set({ hours: hoursData }, { merge: true });
          console.log('Mapa actualizado correctamente');
        } else {
          throw new Error('El documento no existe');
        }
      } catch (error) {
        console.error('Error al actualizar el mapa', error);
      }
    }


    async update2(course: any, index: any, value: any) {
      const docRef = this.db.collection('reservas').doc(course);

      const updateData = {
        [`hours.${index}`]: {
          nombre: value.nombre !== undefined ? value.nombre : '',
          apellidos: value.apellidos !== undefined ? value.apellidos : '',
          email: value.email !== undefined ? value.email : '',
          telefono: value.telefono !== undefined ? value.telefono : '',
          dni: value.dni !== undefined ? value.dni : '',
          tipo: value.tipo !== undefined ? value.tipo : '',
          licencia: value.licencia !== undefined ? value.licencia : '',
          examen: value.examen !== undefined ? value.examen : '',
          tramitacion: value.tramitacion !== undefined ? value.tramitacion : '',
          user_client_id: value.user_client_id !== undefined ? value.user_client_id : '',
        },
        [`hours.${index + 1}`]: {
          nombre: value.nombre !== undefined ? value.nombre : '',
          apellidos: value.apellidos !== undefined ? value.apellidos : '',
          email: value.email !== undefined ? value.email : '',
          telefono: value.telefono !== undefined ? value.telefono : '',
          dni: value.dni !== undefined ? value.dni : '',
          tipo: value.tipo !== undefined ? value.tipo : '',
          licencia: value.licencia !== undefined ? value.licencia : '',

          examen: value.examen !== undefined ? value.examen : '',
          tramitacion: value.tramitacion !== undefined ? value.tramitacion : '',
          user_client_id: value.user_client_id !== undefined ? value.user_client_id : '',
        },
      };

      try {
        const doc = await docRef.get().pipe(first()).toPromise();

        if (doc.exists) {
          const hoursData = (doc.data() as { hours: any }).hours;

          if (hoursData[index]) {
            // Fusionar los datos existentes con los nuevos datos en el índice
            hoursData[index] = { ...hoursData[index], ...updateData[`hours.${index}`] };
          } else {
            hoursData[index] = updateData[`hours.${index}`];
          }

          if (hoursData[index + 1]) {
            // Fusionar los datos existentes con los nuevos datos en el índice +1
            hoursData[index + 1] = { ...hoursData[index + 1], ...updateData[`hours.${index + 1}`] };
          } else {
            hoursData[index + 1] = updateData[`hours.${index + 1}`];
          }

          await docRef.set({ hours: hoursData }, { merge: true });
          console.log('Mapa actualizado correctamente');
        } else {
          throw new Error('El documento no existe');
        }
      } catch (error) {
        console.error('Error al actualizar el mapa', error);
      }
    }


    async eliminarDato(course: any, index: any) {
      const docRef = this.db.collection('reservas').doc(course);

      try {
        const doc = await docRef.get().pipe(first()).toPromise();

        if (doc.exists) {
          const hoursData = (doc.data() as { hours: any }).hours;

          if (hoursData[index]) {
            hoursData.splice(index, 1); // Eliminar el índice utilizando array.splice()
            await docRef.set({ hours: hoursData }, { merge: true });
            console.log('Índice eliminado correctamente');
          } else {
            throw new Error('El índice no existe');
          }
        } else {
          throw new Error('El documento no existe');
        }
      } catch (error) {
        console.error('Error al eliminar el índice', error);
      }
    }


    async agregarDatos(course: string, value: any) {
      const docRef = this.db.collection('reservas').doc(course);

      try {
        const doc = await docRef.get().pipe(first()).toPromise();

        if (doc.exists) {
          const hoursData = (doc.data() as { hours: any[] }).hours;

          const newEntry = {
            hour_end: '',
            hour_start: value.hour_start,
            user_client_id: '',
            user_client_name: '',
          };

          hoursData.push(newEntry); // Agregar la nueva entrada al final del array


          await docRef.set({ hours: hoursData }, { merge: true });
          console.log('Datos agregados correctamente');
        } else {
          throw new Error('El documento no existe');
        }
      } catch (error) {
        console.error('Error al agregar los datos', error);
      }
    }

    async limpiarDatos(course: any, index: any, value: any) {
      const docRef = this.db.collection('reservas').doc(course);

      const updateData = {
        [`hours.${index}`]: {
          nombre: value.nombre,
          apellidos: value.apellidos,
          dni: value.dni,
          email: value.email,

          telefono: value.telefono,
          user_client_id: value.user_client_id,
          user_client_name:  value.user_client_name,
          tipo: value.tipo,
          licencia: value.licencia,
          examen: value.examen,
          tramitacion: value.tramitacion

        }
      };

      try {
        const doc = await docRef.get().pipe(first()).toPromise();

        if (doc.exists) {
          const hoursData = (doc.data() as { hours: any }).hours;
          const existingData = hoursData[index]; // Obtener los datos existentes en el índice

          if (existingData) {
            // Fusionar los datos existentes con los nuevos datos
            hoursData[index] = { ...existingData, ...updateData[`hours.${index}`] };
          } else {
            hoursData[index] = updateData[`hours.${index}`];
          }

          await docRef.set({ hours: hoursData }, { merge: true });
          console.log('Mapa actualizado correctamente');
        } else {
          throw new Error('El documento no existe');
        }
      } catch (error) {
        console.error('Error al actualizar el mapa', error);
      }
    }


    async limpiarDatos2(course: any, index: any, value: any) {
      try {
        const docRef = this.db.collection('reservas').doc(course);
        const doc = await docRef.get().toPromise();
    
        if (doc.exists) {
          const docData = doc.data(); // Obtener los datos del documento
          const hoursData = (docData as { hours?: any }).hours || {}; // Obtener los datos existentes de 'hours' o inicializar un objeto vacío
    
          // Actualizar los datos en el índice dado
          hoursData[index] = value;
    
          // Actualizar el documento en Firebase
          await docRef.set({ hours: hoursData }, { merge: true });
          
          console.log('Mapa actualizado correctamente');
        } else {
          throw new Error('El documento no existe');
        }
      } catch (error) {
        console.error('Error al actualizar el mapa', error);
      }
    }
    
    
    
    

    buscarReservasBloqueadas(codigoReserva: string): Observable<{ id: string; reserva: Reserva; hour_index: number; hour_start: string }[]> {
      return this.db
        .collection<Reserva>('reservas')
        .valueChanges()
        .pipe(
          map((reservas) => {
            return reservas.reduce((acc, reserva) => {
              if (this.hasReservaBloqueada(reserva, codigoReserva)) {
                reserva.hours.forEach((hour, index) => {
                  if (hour.user_client_id === codigoReserva) {
                    acc.push({ id: reserva.id, reserva: reserva, hour_index: index, hour_start: hour.hour_start });
                  }
                });
              }
              return acc;
            }, []);
          })
        );
    }
    
    

    private hasReservaBloqueada(reserva: Reserva, codigoReserva: string): boolean {
      return reserva.hours && reserva.hours.some((hour) => hour.user_client_id === codigoReserva);
    }


}
