import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReservasService } from '../services/reservas.service';
import { environment } from '../../../environments/environment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { format } from 'date-fns';
import { es } from 'date-fns/locale'; // Importa la configuración regional de españo
interface BookingData {
  hours: any[]; // O cualquier otro tipo de datos que tenga la propiedad hours
  // Agregar otras propiedades aquí si es necesario
}



@Component({
  selector: 'app-register',
  templateUrl: './reserva-client.component.html',
  styleUrls: ['./reserva-client.component.css']
})
export class ReservaClientComponent implements OnInit {
  id: any
    @Input() hoursArray: any[];
  @Input() itemId: any;
  @Input() email: any;
  public isFormValid: boolean = false;

  @Input() fecha: any;
  @Input() city: any;
  @Input() selectedIndex: number;
  @Input() reserva;
  public hourList: any[] = [];
  public userModel = JSON.parse(localStorage.getItem(environment.userReservas));
  public formRegister: FormGroup;
  public options: string[];
  public tipos: string[];
  
  public reservasList: any[];
  hour_start: any
  eventstest = [
    /*
    { title: 'All Day Event', start: '2022-08-07' },
    { title: 'Long Event', start: '2022-08-10', end: '2022-08-11' },
    { title: 'Repeating Event', start: '2022-08-11T16:00:00' },
    { title: 'Repeating Event', start: '2022-08-16T16:00:00' },
    { title: 'Conference', start: '2022-08-12', end: '2022-08-13' }
  */];



  validar: any

  public loadBookings: boolean;

  public errorMessages = {
    nombre: [
      { type: 'required', message: 'El nombre es obligatorio' },
    ],
    apellidos: [
      { type: 'required', message: 'Los apellidos son obligatorios' },
    ],
    email: [
      { type: 'required', message: 'El email es obligatorio' },
      { type: 'email', message: 'Por favor, introduce un email válido' },
    ],
    telefono: [
      { type: 'required', message: 'El teléfono es obligatorio' },
    ],
    examen: [
      { type: 'required', message: 'Debes seleccionar un examen' },
    ],
    dni: [
      { type: 'required', message: 'El DNI es obligatorio' },
    ],

    licencia: [
      { type: 'required', message: 'El nº de licencia es obligatorio' },
    ],

    tipo: [
      { type: 'required', message: 'Es obligatorio elegir la posición' },
    ],

    tramitacion: [
      { type: 'required', message: 'Debes seleccionar una opción' },
    ],
  };


  constructor(
    private reservasService: ReservasService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private http: HttpClient,

  ) {

  }

  ngOnInit() {



    this.options = ['--','Inglés', 'Español', 'Inglés + Español'];
    this.tipos = ['--', 'Piloto' , 'ATC'];



    this.formRegister = this.formBuilder.group({
      nombre: new FormControl('', Validators.required),
      apellidos: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      telefono: new FormControl('', Validators.required),
      examen: new FormControl('', Validators.required),
      dni: new FormControl('', Validators.required),
      licencia: new FormControl('', Validators.required),
      tipo: new FormControl('', Validators.required),
      tramitacion: new FormControl('', Validators.required),
    });

    this.formRegister.valueChanges.subscribe(() => {
      this.checkFormValidity();
    });


  }
  checkFormValidity() {
    this.isFormValid = this.formRegister.valid;
  }

  getErrorMessage(controlName: string): string {
    const control = this.formRegister.get(controlName);
    for (const errorKey in control.errors) {
      if (control.errors.hasOwnProperty(errorKey)) {
        const error = this.errorMessages[controlName].find(
          (message) => message.type === errorKey
        );
        return error.message;
      }
    }
    return '';
  }




  changeStatus() {
    Swal.fire({
      title: '¿Deseas confirmar esta reserva?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, confirmar reserva',
      cancelButtonText: 'No, cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        // Proceed with reservation if the user confirms
        const id = this.itemId;
        const index = this.selectedIndex;
        const horas = this.hoursArray[index];
  
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        const dateString = `${day}/${month}/${year}`;
  
        const data = {
          ...this.hoursArray[index],
          nombre: this.formRegister.value.nombre,
          apellidos: this.formRegister.value.apellidos,
          email: this.formRegister.value.email,
          telefono: this.formRegister.value.telefono,
          dni: this.formRegister.value.dni,
          licencia: this.formRegister.value.licencia,
          tipo: this.formRegister.value.tipo,
          examen: this.formRegister.value.examen,
          tramitacion: this.formRegister.value.tramitacion,
          user_client_id: this.formRegister.value.dni,
        };
  
        this.reservasService.getDocument(id).toPromise()
          .then(doc => {
            const datas = doc.data() as BookingData;
            const hours = datas.hours[index];
  
            if (datas.hours[index].user_client_id) {
              alert('La reserva ya no está disponible');
            } else {
              if (hours.hour_start !== datas.hours[index].hour_start) {
                alert('Reserva no disponible');
              } else {
                if (data.examen == 'Inglés + Español') {
                  if (datas.hours[index].user_client_id == '' && datas.hours[index + 1].user_client_id == '') {
                    this.updateinglesespanol(id, index, data);
                  } else {
                    alert('No es posible reservar, los exámenes inglés y español deben ser reservados juntos.');
                  }
                } else {
                  this.update(id, index, data);
                }
              }
            }
          });
      } else {
        // Handle the case where the user cancels the confirmation dialog
        Swal.fire('Reserva cancelada', 'No se ha realizado la reserva.', 'info');
      }
    });
  }
  



  update(id, index, data){



    this.reservasService.updateCoursesInstructor(id, index, data)
    .then(() => {
      this.alert(data);
      this.sendEmail(data.hour_start)
      this.sendEmailInstructor(data.hour_start)
    })
    .catch((error) => {
      console.error('Error al actualizar el mapa', error);
    });

  }


  updateinglesespanol(id, index, data){
    this.reservasService.update2(id, index, data)
    .then(() => {
      this.alert(data);
      this.sendEmail(data.hour_start)
      this.sendEmailInstructor(data.hour_start)
    })
    .catch((error) => {
      console.error('Error al actualizar el mapa', error);
    });

  }



  alert(data: any) {


    const fechaEnEspanol = format(new Date(this.fecha), 'dd/MM/yyyy', { locale: es });


    const index = this.selectedIndex;
    const hora = this.hoursArray[index];

    Swal.fire({
      title: '<strong>Reserva Realizada</strong>',
      icon: 'success',
      html:
        '<ul style="text-align: left;">' +
        '<li><strong>Fecha:</strong> ' + fechaEnEspanol + '</li>' +
        '<li><strong>Hora:</strong> ' + hora.hour_start + '</li>' +
        '<li><strong>Nombre:</strong> ' + data.nombre + '</li>' +
        '<li><strong>Apellidos:</strong> ' + data.apellidos + '</li>' +
        '<li><strong>Email:</strong> ' + data.email + '</li>' +
        '<li><strong>Teléfono:</strong> ' + data.telefono + '</li>' +
        '<li><strong>Posición:</strong> ' + data.tipo + '</li>' +
        '<li><strong>Examen:</strong> ' + data.examen + '</li>' +
        '</ul>',

      focusConfirm: true,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Aceptar',
      confirmButtonAriaLabel: 'De acuerdo',
    }).then(() => {
      this.activeModal.close();

      setTimeout(() => {
        window.location.reload();
      }, 2000);

    });
  }
  
 


  addReserva() {
    const horas = this.hoursArray[this.selectedIndex]
    const id = this.itemId;
    const index = this.selectedIndex;
    const hourUpdate = {
      [index]: {
      horas,
      nombre: this.formRegister.value.nombre,
      email: this.formRegister.value.email,
      telefono: this.formRegister.value.telefono,
      dni: this.formRegister.value.dni,
      licencia: this.formRegister.value.licencia,
      tipo: this.formRegister.value.tipo,
      examen: this.formRegister.value.examen,
      tramitacion: this.formRegister.value.tramitacion,
      user_client_id: this.formRegister.value.dni
    }
    };



    const actualizar = {

      nombre: this.formRegister.value.nombre,
      email: this.formRegister.value.email,
      telefono: this.formRegister.value.telefono,
      dni: this.formRegister.value.dni,
      licencia: this.formRegister.value.licencia,
      tipo: this.formRegister.value.tipo,
      examen: this.formRegister.value.examen,
      tramitacion: this.formRegister.value.tramitacion,
      user_client_id: this.formRegister.value.dni

    }


    const hours = {
      ...this.hoursArray, [index]: {

      }
    }












    // this.reservasService.updateHourIndex(id, {hours[index]: this.hoursArray[index]})
    //   .then(() => {
    //     console.log('Índice actualizado correctamente.');
    //   })
    //   .catch((error) => {
    //     console.error('Error al actualizar el índice:', error);
    //   });

    this.activeModal.close();

  }


  addReservaClient() {
    const index = this.selectedIndex;
    const reservaHour = this.hoursArray[index];




    this.updateReserva(index, this.itemId,  this.formRegister.value.email, this.formRegister.value.nombre, this.formRegister.value.apellidos,  this.formRegister.value.telefono, this.formRegister.value.tipo, this.formRegister.value.examen,  this.formRegister.value.dni, this.formRegister.value.licencia, this.formRegister.value.tramitacion,);
    this.activeModal.close()
  }


  private updateReserva(index: number, userClientId, userName, nombre, apellidos, telefono, examen, tipo, licencia, dni, tramitacion) {

    const reservaFecha = this.reserva.date
    const hourTempList = this.hourList;
    const hourUpdate = {
      ...this.hourList[index], ...{
        user_client_id: userClientId,
        user_client_name: userName,
        nombre: nombre,
        apellidos: apellidos,
        telefono: telefono,
        examen: examen,
        tipo: tipo,
        licencia: licencia,
        dni: dni,
        tramitacion: tramitacion,
      }
    };

    hourTempList[index] = hourUpdate;
    const model = {
      id: this.reserva.id,
      hours: hourTempList
    };

    this.reservasService.reservaUpdate(model).then(() => {
      this.hourList[index] = hourUpdate;
      Swal.fire('Reserva confirmada<br>' + hourUpdate.nombre + '' + hourUpdate.apellidos +'<br>'+ reservaFecha +'<br>'+ hourUpdate.hour_start + '-' + hourUpdate.hour_end)


    }).catch(error => {
      alert('error');
    });
  }


  sendEmailInstructor(hour_start: any) {
    const examen = this.formRegister.value.examen

    const datos = this.formRegister.value

    const index = this.selectedIndex
    const hora = this.hoursArray[index].hour_start
    const fecha = this.fecha
    const fechaEnEspanol = format(new Date(this.fecha), 'dd/MM/yyyy', { locale: es });

    const dest = [this.email, 'mayte.jimenez@qualiflight.aero'];

    const subject = 'Han añadido una nueva reserva 📅'
    const html = `<!DOCTYPE html>
    <html lang="es">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Nueva reserva</title>
      <style>
        body {
          font-family: Arial, sans-serif;
          font-size: 16px;
          margin: 0;
          padding: 0;
        }
    
        .container {
          max-width: 600px;
          margin: 20px auto;
          padding: 20px;
          border: 1px solid #ddd;
          border-radius: 8px;
        }
    
        h2 {
          color: #0077cc;
        }
    
        h3 {
          font-size: 1.2em;
          margin-bottom: 10px;
        }
    
        p {
          margin-bottom: 10px;
        }
    
        hr {
          border: 0;
          border-top: 1px solid #ddd;
          margin: 20px 0;
        }
    
        .footer {
          font-size: 12px;
          color: #999999;
          margin-top: 20px;
          border-top: 1px solid #ddd;
          padding-top: 10px;
        }
    
        .logo {
          display: block;
          margin: 0 auto;
          max-width: 100%;
        }
      </style>
    </head>
    <body>
      
      <div class="container">
    
        <img src="https://qualiflight.aero/wp-content/uploads/2020/04/Qualiflight-aviation-training-05-05-300x83.png" alt="Logo de Qualiflight" style="display: block; margin: 0 auto; max-width: 100%;">
    
    
        <h2>Nueva reserva realizada</h2>
    
    <div> 👤 <strong>Nombre:</strong>  ${datos.nombre}  ${datos.apellidos}</div>
        <div> 🗓️ <strong>Día de reserva:</strong>  ${fechaEnEspanol}</div>
        <div>⏰ <strong>Hora:</strong>  ${hour_start}</div>
        <div>📍 <strong>Lugar:</strong> Qualiflight Madrid </div>
    
        
    
    
        <p class="footer">Este correo electrónico fue generado automáticamente. Por favor, no responda a este mensaje.</p>
      </div>
    </body>
    </html>
    
    `;




    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        /*'Authorization': 'secret-key'*/
      })
    }

    const data = {html, subject, dest}

    const host = 'https://us-central1-reservas-20861.cloudfunctions.net/emailSender'


    for (let i = 0; i < dest.length; i++) {
      const data = {
        html,
        subject,
        dest: dest[i]
      }


          this.http.post(host, data, httpOptions).subscribe({
      next: (response) => {
      },
      error: (error) => {
      },
      complete: () => {
      }
    });


    }

  }


  verLugar() {


  }




  sendEmail(hour_start: any) {

    let direccion = ''; // Inicializa la variable direccion fuera del bloque condicional


    if (this.city == 'Madrid') {
      direccion = 'C/ Playa de Riazor 22 – 2º planta 28042 Madrid (España)'; // Asigna el valor dentro del bloque condicional
  }

  if (this.city == 'Malaga') {
    direccion = 'C/ Decano Antonio Zedano 3 - Ruiz Escobar 1 Local 20 C.P.29624 Torremolinos -Málaga (España)'; // Asigna el valor dentro del bloque condicional
}


const email = this.formRegister.value.email.toLowerCase().trim(); // Convert to lowercase and remove whitespace
const codigo = this.formRegister.value.dni
    const index = this.selectedIndex
    const hora = this.hoursArray[index].hour_start
    const fecha = this.fecha

    const fechaEnEspanol = format(new Date(fecha), 'dd/MM/yyyy', { locale: es });

    const dest = [email];
    const subject = 'Reserva realizada 📅'
    const html = `<!DOCTYPE html>
    <html lang="es">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Confirmación de reserva</title>
      <style>
        body {
          font-family: Arial, sans-serif;
          font-size: 16px;
          margin: 0;
          padding: 0;
        }
    
        .container {
          max-width: 600px;
          margin: 20px auto;
          padding: 20px;
          border: 1px solid #ddd;
          border-radius: 8px;
        }
    
        h2 {
          color: #0077cc;
          font-size: 30px;
        }
    
        h3 {
          font-size: 1.2em;
          margin-bottom: 10px;
        }
    
        p {
          margin-bottom: 10px;
        }
    
        a {
          color: #0077cc;
          text-decoration: none;
        }
    
        a:hover {
          text-decoration: underline;
        }
    
        .footer {
          font-size: 12px;
          color: #999999;
          margin-top: 20px;
          border-top: 1px solid #ddd;
          padding-top: 10px;
        }
      </style>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap">
    </head>
    <body>
    
      <div class="container">
        <img src="https://qualiflight.aero/wp-content/uploads/2020/04/Qualiflight-aviation-training-05-05-300x83.png" alt="Logo de Qualiflight" style="display: block; margin: 0 auto; max-width: 100%;">
    
        <h2>Reserva confirmada ✅</h2>
    
        <div> 🗓️ <strong>Día de reserva:</strong>  ${fechaEnEspanol}</div>
        <div>⏰ <strong>Hora:</strong>  ${hour_start}</div>
        <div>📍 <strong>Lugar:</strong> Qualiflight ${this.city} </div>
        <div style="font-size: 14px; padding-bottom: 10px;"><em>${direccion}</em></div>
        
        <div>➡️ <strong>Código de reserva:</strong> ${codigo}</div>
        
    
    
       
    
        <p class="footer">Este correo electrónico fue generado automáticamente. Por favor, no responda a este mensaje.</p>
      </div>
    </body>
    </html>
    `;




    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        /*'Authorization': 'secret-key'*/
      })
    }

    const data = {html, subject, dest}

    const host = 'https://us-central1-reservas-20861.cloudfunctions.net/emailSender'

    for (let i = 0; i < dest.length; i++) {
      const data = {
        html,
        subject,
        dest: dest[i]
      }


          this.http.post(host, data, httpOptions).subscribe({
      next: (response) => {

      },
      error: (error) => {
      },
      complete: () => {
        console.log('Request completed');
      }
    });


    }


    // console.log(data)
    // this.http.post(host, data, httpOptions).subscribe({
    //   next: (response) => {
    //     console.log(response);
    //   },
    //   error: (error) => {
    //     console.error(error);
    //   },
    //   complete: () => {
    //     console.log('Request completed');
    //   }
    // });
  }







}
